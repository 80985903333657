import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';

const Team = ({ data }) => (
  <Layout
    title={data.datoCmsTeam.seo && data.datoCmsTeam.seo.title}
    description={data.datoCmsTeam.seo && data.datoCmsTeam.seo.description}
  >
    <div className="common-page">
      <Subheader title="TEAM" />

      <div className="container">
        <div className="row">
          <div
            className="col page-team"
            dangerouslySetInnerHTML={{
              __html: data.datoCmsTeam.text
            }}
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default Team;

export const query = graphql`
  query TeamQuery {
    datoCmsTeam {
      text
      seo {
        title
        description
      }
    }
  }
`;
